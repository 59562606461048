<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:35:55
 * @LastEditTime: 2023-03-24 16:35:17
 * @Descripttion: 公开课【详情】
-->
<style lang="scss" scoped>
.class-detail {
    @include innerPage(40px 0 48px);
    @include pageTitle(34px);

    &-inner {
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 34px;
        margin-top: 34px;
        overflow: hidden;
        overflow-y: auto;

        h3 {
            line-height: 38px;
            color: #232323;
            text-align: center;
        }

        .other-info {
            height: 30px;
            font-size: 16px;
            color: #696969;
            margin-top: 8px;
            @include flexBox(center);

            span {
                margin: 0 20px;
            }
        }
    }

    &--wrapper {
        width: 100%;
        margin: 30px auto 0;
        @include flexBox;
        align-items: flex-start;

        .label {
            font-size: 16px;
            color: #222222;
        }

        .courseware {
            width: 16.53%;
            margin: 0 auto;
            @include flexBox;

            .iconfont-color {
                font-size: 44px;
            }

            .el-button {
                margin-left: auto;
            }
        }

        .playback {
            width: 70.13%;
            margin: 0 auto;
            @include flexBox;
            align-items: flex-start;

            .label {
                width: 106px;
                line-height: 34px;
            }

            .medium {
                width: calc(100% - 106px);
            }

            img {
                width: 100%;
                height: auto;
                border-radius: 30px;

            }
        }
    }

}
</style>

<template>
    <section class="class-detail">
        <div class="page-title">
            <h3>公开课详情</h3>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="class-detail-inner">
            <h3 class="bold">{{ detailData.sccou_cha_titles_name || '-' }}</h3>
            <div class="other-info pf_medium">
                <span>班级：{{ detailData.teope_grade_name }}{{ detailData.sccla_name }}</span>
                <span>科目：{{ detailData.sysub_name }}</span>
                <span>授课老师：{{ detailData.teuse_name }}</span>
            </div>
            <div class="class-detail--wrapper">
                <div class="playback" v-if="detailData.teope_video_url">
                    <p class="label">视频回放：</p>
                    <div class="medium">
                        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                            :options="playerOptions" />
                    </div>
                </div>
                <div class="courseware" v-else>
                    <p class="label">课件：</p>
                    <i class="iconfont-color" v-html="fileType(detailData.tetea_type)"></i>
                    <el-button type="custom_primary" size="medium" @click="downloadFile" :disabled="btnLoad" v-loading="btnLoad">
                        下载
                    </el-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { $publicClassDetail } from "@api/resources"
import { $downloadFile } from "@api/common"
import { formatFile, formatFileType } from "@utils"
export default {
    name: 'resources_publicClassDetail',
    computed: {
        fileType() {
            return function (val) {
                return formatFileType(val);
            }
        },
    },
    data() {
        return {
            detailData: {},
            btnLoad: false,
            /* 视频播放器配置 */
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: '', // 视频封面
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controls: true,
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            }

        }
    },
    created() {
        this.getDetail();
    },
    methods: {
        /** 获取公开课详情 */
        async getDetail() {
            let { data } = await $publicClassDetail(this.$route.params.id);
            this.detailData = { ...data };
            this.playerOptions = {
                ...this.playerOptions,
                sources: [{
                    type: 'video/mp4',
                    src: formatFile(data.teope_video_url)
                }]
            };
            this.$forceUpdate();
        },
        /** 文件下载 */
        async downloadFile() {
            this.btnLoad = true;
            let { data } = await $downloadFile(this.detailData.tetea_url);
            this.btnLoad = false;
            if (data.url) {
                this.$fileDownload(data.url)
            };
        }
    }
}
</script>